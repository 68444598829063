require.config({

    // baseUrl: '', // baseUrl jest pobierany z data-main
    paths: {
        'jquery': 'vendor/jquery/jquery',
        'domReady': 'vendor/requirejs/domReady',
        'bootstrap': 'vendor/bootstrap/bootstrap',
        'metisMenu': 'vendor/metismenu/metisMenu',
        'tipso': 'vendor/tipso/tipso',
        'jquery-validate': 'vendor/jquery/jquery.validate',
        'jquery-flexslider': 'vendor/flexslider/jquery.flexslider',

        'leaflet': 'vendor/leaflet/leaflet-src',
        'leaflet-geocoder-mapzen': 'vendor/leaflet/leaflet-geocoder-mapzen',
        'sweetalert': 'vendor/sweetalert/sweetalert-dev',
        'slick': 'vendor/slick/slick'
    },
    shim: {
        'bootstrap': ['jquery'],
        'jquery-flexslider': ['jquery']

    }
});

define(function (require) {
    var $ = require('jquery');
    var domReady = require('domReady');
    var tipso = require('tipso');
    var slick = require('slick');

    domReady(function () {

        // scroll-to-top button
        $("#btn_up").click(function () {
            event.preventDefault();
            $("html, body").animate({
                scrollTop: 0
            }, "slow");
        });

        $('a[href*="#"]:not([href="#"])').click(function () {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top - 60
                    }, 1000);
                    return false;
                }
            }
        });

        // tipso
        $('.tip').tipso({
            position: 'bottom',
            background: '#01A0E4',
            useTitle: true
        });

        $("#top-premium").slick({
            dots: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 6000,
            arrows: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });

        /**************** Favorites add/delete *********************/
        $('.card-footer-favorite').on('click', function (event) {
            event.preventDefault(); //disable a href when click on star
            var data = {};
            var t = $(this);
            data.id = $(this).find('.fav_adv_id').val();

            $.post(base_url + 'favorites/addToFavorites', data, function (json) {
                if (t.hasClass('fav_added')) {
                    t.removeClass('fav_added');
                    t.find('.fav_desc').text("Dodaj do schowka");
                }else{
                    t.addClass('fav_added');
                    t.find('.fav_desc').text("Usuń ze schowka");
                }
            });
        });
    });
});